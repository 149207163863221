<template>
  <div>
    <div class="sctp-container sctp-mar-b15">
      <div class="sctp-pad-t10">
        <el-breadcrumb separator=">">
          <el-breadcrumb-item>首页</el-breadcrumb-item>
          <el-breadcrumb-item>店铺详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <template v-if="notExist === true">
        <div style="padding: 50px 0;" class="sctp-bg-white">
          <empty :message="'店铺不存在'"></empty>
        </div>
      </template>
      <template v-else>
        <div class="sctp-mar-t10">
          <el-row :gutter="10">
            <el-col :span="5">
              <div class="sctp-pad15 sctp-bg-white shop-introduction"
                   style="border: 1px solid #eee;">
                <div class="sctp-flex sctp-pad-b15" style="border-bottom: 1px solid #eee;">
                  <el-avatar class="sctp-mar-r10" :src="shopInfo && shopInfo.logo"></el-avatar>
                  <div class="sctp-flex-item-1 sctp-flex sctp-flex-sb sctp-flex-column">
                    <div>{{ shopInfo && shopInfo.shopname }}的小店</div>
                    <div class="sctp-flex sctp-font-12">
                      <div class="sctp-mar-r5"><span><img class="auth_ok_icon"
                                                          src="/images/icon/shop_ok.png"/></span><span>店铺认证</span>
                      </div>
                      <div><span><img class="auth_ok_icon"
                                      src="/images/icon/user_ok.png"/></span><span>实名认证</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="sctp-info sctp-font-14 sctp-pad-tb10 shop-info">
                  <div class="sctp-flex">
                    <div class="title">店家：</div>
                    <div class="value">{{ shopInfo && shopInfo.shopname }}</div>
                  </div>
                  <div class="sctp-flex">
                    <div class="title">宝贝：</div>
                    <div class="value">{{ shopInfo && shopInfo.goodsCount }}件</div>
                  </div>
                  <div class="sctp-flex">
                    <div class="title">地址：</div>
                    <div class="value">{{ shopAddress }}</div>
                  </div>
                  <div class="sctp-flex">
                    <div class="title">创店：</div>
                    <div class="value">{{
                        shopInfo && shopInfo.createtime | dateString('yyyy-MM-dd')
                      }}
                    </div>
                  </div>
                  <div class="sctp-flex">
                    <div class="title">联系：</div>
                    <div class="value"><a class="sctp-blue"
                                          @click="doPrivileged(viewPhoneDialogConfig.onViewClick)">查看联系方式</a>
                    </div>
                  </div>
                </div>
                <div
                  v-if="!(shopInfo && user && shopInfo.userid === user.userId)"
                >
                  <el-button v-if="shopInfo && shopInfo.favorite"
                             @click="doPrivileged(favoriteShop)"
                             size="mini" icon="el-icon-star-on" type="primary"
                             :loading="!collectionBtnCanClickFlag"
                  >
                    已收藏
                  </el-button>
                  <el-button v-else
                             :loading="!collectionBtnCanClickFlag"
                             @click="doPrivileged(favoriteShop)"
                             size="mini" icon="el-icon-star-off"
                             class="mg-l5"
                  >收藏店铺
                  </el-button>
                </div>
                <div class="mg-t10" v-if="shopInfo && shopInfo.deposit">
                  <el-alert
                    class="deposit-alter"
                    center
                    type="success"
                    :closable="false">
                    <div slot="title">
                      商家已缴纳保证金<span class="sctp-color-main fz-16">{{
                        shopInfo.deposit.toMoney()
                      }}</span>元
                    </div>
                  </el-alert>
                </div>
              </div>
            </el-col>
            <el-col :span="19">
              <el-tabs class="custom-tabs" value="1" type="card">
                <el-tab-pane lazy :label="'所有源码（' + sourceCodeConfig.pageConfig.total + '）'"
                             name="1">
                  <div class="sctp-bg-white sctp-pad15 sctp-mar-b15 goods-title-bar">
                    <div class="sctp-flex sctp-flex-sb">
                      <div>
                        <span style="height: 100%;" class="sctp-flex sctp-flex-center">所有源码（{{
                            sourceCodeConfig.pageConfig.total
                          }}）</span>
                      </div>
                      <el-input v-model="sourceCodeConfig.search.keyword" placeholder="输入关键字">
                        <el-button @click="sourceCodeConfig.loadData()" slot="append"
                                   icon="el-icon-search"></el-button>
                      </el-input>
                    </div>
                  </div>
                  <div class="goods-container">
                    <el-row :gutter="10">
                      <el-col :span="6"
                              :key="product.goodsid"
                              v-for="product in sourceCodeConfig.data">
                        <router-link :to="'/sourcecode/view/' + product.goodsid">
                          <div class="goods-card sctp-bg-white sctp-flex sctp-flex-column">
                            <el-image style="width: 100%;height: 200px;"
                                      lazy
                                      :src="product.goodslogo" fit="cover"></el-image>
                            <div :title="product.goodsname" style="height: 32px;"
                                 class="sctp-ellipsis--l2 sctp-mar-tb5">{{
                                product.goodsname
                              }}
                            </div>
                            <div class="sctp-flex sctp-flex-sb sctp-mar-tb5">
                              <div class="sctp-red">{{
                                  product.saleprice ? `¥${product.saleprice}` : '免费'
                                }}
                              </div>
                              <div class="sctp-font-14">销量：{{ product.sales_volume }}</div>
                            </div>
                          </div>
                        </router-link>
                      </el-col>
                    </el-row>
                  </div>
                  <div class="sctp-pad-tb10 sctp-tr sctp-bg-white sctp-mar-t5">
                    <el-pagination
                      :current-page="sourceCodeConfig.pageConfig.page"
                      :page-size="sourceCodeConfig.pageConfig.pageSize"
                      @size-change="sourceCodeConfig.pageConfig.handleSizeChange"
                      @current-change="sourceCodeConfig.pageConfig.handleCurrentChange"
                      :page-sizes="sourceCodeConfig.pageConfig.pageSizes"
                      layout="prev, pager, next, total, sizes"
                      :total="sourceCodeConfig.pageConfig.total">
                    </el-pagination>
                  </div>
                </el-tab-pane>
                <el-tab-pane lazy :label="'所有服务（' + serviceConfig.pageConfig.total + '）'" name="2">
                  <div class="sctp-bg-white sctp-pad15 sctp-mar-b15 goods-title-bar">
                    <div class="sctp-flex sctp-flex-sb">
                      <div>
                        <span style="height: 100%;" class="sctp-flex sctp-flex-center">所有服务（{{
                            serviceConfig.pageConfig.total
                          }}）</span>
                      </div>
                      <el-input v-model="serviceConfig.search.keyword" placeholder="输入关键字">
                        <el-button @click="serviceConfig.loadData()" slot="append"
                                   icon="el-icon-search"></el-button>
                      </el-input>
                    </div>
                  </div>
                  <div class="goods-container">
                    <el-row :gutter="10">
                      <el-col :span="6"
                              :key="service.serviceid"
                              v-for="service in serviceConfig.data">
                        <router-link :to="'/service/view/' + service.serviceid">
                          <div class="goods-card sctp-bg-white sctp-flex sctp-flex-column">
                            <el-image style="width: 100%;height: 200px;"
                                      lazy
                                      :src="service.servicecovers[0]" fit="cover"></el-image>
                            <div :title="service.servicename" style="height: 32px;"
                                 class="sctp-ellipsis--l2 sctp-mar-tb5">{{
                                service.servicename
                              }}
                            </div>
                            <div class="sctp-flex sctp-flex-sb sctp-mar-tb5">
                              <div class="sctp-red">¥{{ service.serviceprice }}</div>
                              <div class="sctp-font-14"></div>
                            </div>
                          </div>
                        </router-link>
                      </el-col>
                    </el-row>
                  </div>
                  <div class="sctp-pad-tb10 sctp-tr sctp-bg-white sctp-mar-t5">
                    <el-pagination
                      :current-page="serviceConfig.pageConfig.page"
                      :page-size="serviceConfig.pageConfig.pageSize"
                      @size-change="serviceConfig.pageConfig.handleSizeChange"
                      @current-change="serviceConfig.pageConfig.handleCurrentChange"
                      :page-sizes="serviceConfig.pageConfig.pageSizes"
                      layout="prev, pager, next, total, sizes"
                      :total="serviceConfig.pageConfig.total">
                    </el-pagination>
                  </div>
                </el-tab-pane>
                <el-tab-pane lazy :label="'所有设计（' + designConfig.pageConfig.total + '）'" name="3">
                  <div class="sctp-bg-white sctp-pad15 sctp-mar-b15 goods-title-bar">
                    <div class="sctp-flex sctp-flex-sb">
                      <div>
                        <span style="height: 100%;" class="sctp-flex sctp-flex-center">所有设计（{{
                            designConfig.pageConfig.total
                          }}）</span>
                      </div>
                      <el-input v-model="designConfig.search.keyword" placeholder="输入关键字">
                        <el-button @click="designConfig.loadData()" slot="append"
                                   icon="el-icon-search"></el-button>
                      </el-input>
                    </div>
                  </div>
                  <div class="goods-container">
                    <el-row :gutter="10">
                      <template v-for="design in designConfig.data">
                        <el-col :span="6">
                          <a :href="`/design/view/${design.designid}`" target="_blank">
                            <div class="sctp-bg-white sctp-flex sctp-flex-column sctp-mar-t10">
                              <el-image
                                lazy
                                style="width: 100%;height: 200px;"
                                :src="design.designcoverImgurl" fit="cover"
                              ></el-image>
                              <div class="" style="padding: 0 8px 8px;">
                                <div :title="design.designname" class="sctp-ellipsis sctp-mar-tb5">
                                  {{
                                    design.designname
                                  }}
                                </div>
                                <div class="sctp-flex sctp-flex-sb sctp-mar-tb5">
                                  <div class="sctp-red">{{
                                      design.saleprice ? `¥${design.saleprice}` : '免费'
                                    }}
                                  </div>
                                  <div class="">浏览数：{{ design.hits || 0 }}</div>
                                </div>
                              </div>
                            </div>
                          </a>
                        </el-col>
                      </template>
                    </el-row>
                  </div>
                  <div class="sctp-pad-tb10 sctp-tr sctp-bg-white sctp-mar-t5">
                    <el-pagination
                      :current-page="serviceConfig.pageConfig.page"
                      :page-size="serviceConfig.pageConfig.pageSize"
                      @size-change="serviceConfig.pageConfig.handleSizeChange"
                      @current-change="serviceConfig.pageConfig.handleCurrentChange"
                      :page-sizes="serviceConfig.pageConfig.pageSizes"
                      layout="prev, pager, next, total, sizes"
                      :total="serviceConfig.pageConfig.total">
                    </el-pagination>
                  </div>
                </el-tab-pane>
                <el-tab-pane lazy :label="'所有文档（' + docConfig.pageConfig.total + '）'" name="4">
                  <div class="sctp-bg-white sctp-pad15 sctp-mar-b15 goods-title-bar">
                    <div class="sctp-flex sctp-flex-sb">
                      <div>
                        <span style="height: 100%;" class="sctp-flex sctp-flex-center">所有文档（{{
                            docConfig.pageConfig.total
                          }}）</span>
                      </div>
                      <el-input v-model="docConfig.search.keyword" placeholder="输入关键字">
                        <el-button @click="docConfig.loadData()" slot="append"
                                   icon="el-icon-search"></el-button>
                      </el-input>
                    </div>
                  </div>
                  <div class="goods-container">
                    <el-row :gutter="10">
                      <template v-for="document in docConfig.data">
                        <el-col :span="6">
                          <a :href="`/techdocs/view/${document.docid}`" target="_blank">
                            <div class="sctp-bg-white sctp-flex sctp-flex-column sctp-mar-t10">
                              <el-image
                                lazy
                                style="width: 100%;height: 200px;"
                                :src="document.doccoverImgurl" fit="cover"
                              ></el-image>
                              <div class="" style="padding: 0 8px 8px;">
                                <div :title="document.docname" class="sctp-ellipsis sctp-mar-tb5">
                                  {{ document.docname }}
                                </div>
                                <div class="sctp-flex sctp-flex-sb sctp-mar-tb5">
                                  <div class="sctp-red">{{
                                      document.saleprice ? `¥${document.saleprice}` : '免费'
                                    }}
                                  </div>
                                  <div class="">浏览数：{{ document.hits || 0 }}</div>
                                </div>
                              </div>
                            </div>
                          </a>
                        </el-col>
                      </template>
                    </el-row>
                  </div>
                  <div class="sctp-pad-tb10 sctp-tr sctp-bg-white sctp-mar-t5">
                    <el-pagination
                      :current-page="serviceConfig.pageConfig.page"
                      :page-size="serviceConfig.pageConfig.pageSize"
                      @size-change="serviceConfig.pageConfig.handleSizeChange"
                      @current-change="serviceConfig.pageConfig.handleCurrentChange"
                      :page-sizes="serviceConfig.pageConfig.pageSizes"
                      layout="prev, pager, next, total, sizes"
                      :total="serviceConfig.pageConfig.total">
                    </el-pagination>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </el-col>
          </el-row>
        </div>
      </template>
    </div>
    <el-dialog
      center
      title="查看联系方式"
      width="600px"
      :visible.sync="viewPhoneDialogConfig.showPayDialog"
    >
      <pay :pay-amount="shopInfo && shopInfo.viewContactPrice || 0"
           @pay="viewPhoneDialogConfig.onPay"
           :can-use-reward="true"
      ></pay>
    </el-dialog>
    <el-dialog
      center
      title="联系方式"
      width="500px"
      :visible.sync="viewPhoneDialogConfig.show"
    >
      <div>
        <div class="flex pad-tb5">
          <div class="sctp-tr" style="width: 100px;">手机号码：</div>
          <div>{{ shopInfo && shopInfo.phone || '未设置' }}</div>
        </div>
        <div class="flex pad-tb5">
          <div class="sctp-tr" style="width: 100px;">Email邮箱：</div>
          <div>{{ shopInfo && shopInfo.email || '未设置' }}</div>
        </div>
        <div class="flex pad-tb5">
          <div class="sctp-tr" style="width: 100px;">QQ号：</div>
          <div>{{ shopInfo && shopInfo.qq || '未设置' }}</div>
        </div>
        <div class="flex pad-tb5">
          <div class="sctp-tr" style="width: 100px;">微信号：</div>
          <div>{{ shopInfo && shopInfo.wechat || '未设置' }}</div>
        </div>
        <div class="flex pad-tb5">
          <div class="sctp-tr" style="width: 100px;">微信二维码：</div>
          <div>
            <template v-if="shopInfo && shopInfo.wechat_qrcode">
              <el-image
                lazy
                :src="shopInfo.wechat_qrcode"
                style="width: 80px;height: 80px;"
                fit="contain"
              ></el-image>
            </template>
            <template v-else>
              未设置
            </template>
          </div>
        </div>
        <div class="fz-12 fc-info">
          注：以上由卖家自行填写，请及时联系，联系时请说明信息来源。
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="查看联系方式需支付服务费"
      :visible.sync="openPayAfterFlag"
      width="26%"
      class="register-dialog"
      center>
      <div>
        <div style="text-align: center;color: red;font-size: 20px">{{shopInfo.viewContactPrice.toMoney()}}元</div>
        <div style="text-align: left;">确认支付代表知晓并同意本次服务费及平台的免责条款，以下联系方式仅打勾的有效</div>
        <div class="flex flex-center">
          <div class="sctp-mar-r10"><i class="el-icon-success sctp-color-main" v-if="shopInfo && shopInfo.hasPhone"></i>
            <i class="el-icon-error" v-else></i>手机号</div>
          <div class="sctp-mar-r10"><i class="el-icon-success sctp-color-main" v-if="shopInfo && shopInfo.hasEmail"></i>
            <i class="el-icon-error" v-else></i>邮箱</div>
          <div class="sctp-mar-r10">
            <i class="el-icon-success sctp-color-main" v-if="shopInfo && shopInfo.hasQQ"></i>
            <i class="el-icon-error" v-else></i>QQ</div>
          <div class="sctp-mar-r10">
            <i class="el-icon-success sctp-color-main" v-if="shopInfo && shopInfo.hasWechat"></i>
            <i class="el-icon-error" v-else></i>微信</div>
        </div>
      </div>
      <div class="sctp-flex sctp-flex-center" style="padding-top: 10px">
        <el-checkbox v-model="read">
          我已阅读
          <a @click.prevent="centerDialogVisible = true" class="sctp-color-main">《平台服务条款》</a>
          并同意
        </el-checkbox>
      </div>
      <div slot="footer" class="dialog-footer">

        <el-button @click="openPayAfterFlag = false" >取消</el-button>
        <el-button type="primary" @click="openPay()">支付</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="平台服务条款"
      :visible.sync="centerDialogVisible"
      class="register-dialog"
      center>
      <div class="rich-text" v-html="productReleaseTerms"></div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {collection, common, shop} from "../../../apis";
import area from "@/resources/js/area";

const config = require('../../../resources/js/config');
export default {
  name: 'shopViewPage',
  props: {
    id: {
      type: Number
    }
  },
  components: {
    empty: () => import('@CMP/common/EmptyData.vue'),
    pay: () => import('@CMP/common/BuyPay'),
  },
  data() {
    return {
      notExist: null,
      shopInfo: null,
      keyword: null,
      collectionBtnCanClickFlag: true,
      productReleaseTerms:null,
      centerDialogVisible:false,
      read:false,
      openPayAfterFlag:false,
      viewPhoneDialogConfig: {
        show: false,
        showPayDialog: false,
        onViewClick: () => {
          if (!this.shopInfo) {
            return;
          }
          if (this.shopInfo.showContact === true) {
            this.viewPhoneDialogConfig.show = true;
          } else {
            common.contactClickHistory({
              userId: this.user.userId,
              type: 1,
              id: this.id,
            });
            this.viewPhoneDialogConfig.confirmPay();
          }
        },
        confirmPay: () => {
          if (!this.shopInfo) {
            return;
          }
          this.openPayAfterFlag = true
          // let {shopInfo} = this;
          // this.$confirm(`<div>
          //     <div class="sctp-red sctp-font-18">${shopInfo.viewContactPrice.toMoney()}元</div>
          //     <div style="text-align: left;">确认支付代表知晓并同意本次服务费及平台的免责条款，以下联系方式仅打勾的有效</div>
          //     <div class="flex flex-center">
          //     <div class="sctp-mar-r10">${shopInfo && shopInfo.hasPhone
          //   ? '<i class="el-icon-success sctp-color-main"></i>' : '<i class="el-icon-error"></i>'}手机号</div>
          //     <div class="sctp-mar-r10">${shopInfo && shopInfo.hasEmail
          //   ? '<i class="el-icon-success sctp-color-main"></i>' : '<i class="el-icon-error"></i>'}邮箱</div>
          //     <div class="sctp-mar-r10">${shopInfo && shopInfo.hasQQ
          //   ? '<i class="el-icon-success sctp-color-main"></i>' : '<i class="el-icon-error"></i>'}QQ</div>
          //     <div class="sctp-mar-r10">${shopInfo && shopInfo.hasWechat
          //   ? '<i class="el-icon-success sctp-color-main"></i>' : '<i class="el-icon-error"></i>'}微信</div>
          //     </div>
          //     </div>`, {
          //   dangerouslyUseHTMLString: true,
          //   confirmButtonText: '支付',
          //   cancelButtonText: '取消',
          //   center: true,
          //   title: '查看联系方式需支付服务费',
          // }).then(() => {
          //   this.viewPhoneDialogConfig.showPayDialog = true;
          // })
        },
        onPay: ({payWay}) => {
          shop.viewContactInformation({
            userId: this.user.userId,
            shopId: this.id,
            payWay: payWay,
            eType: 1,
            eId: this.id
          }).then(() => {
            this.viewPhoneDialogConfig.showPayDialog = false;
            this.getShopInfo().then(res => {
              this.viewPhoneDialogConfig.show = true;
            })
          })
        },
      },
      sourceCodeConfig: {
        search: {
          keyword: null,
        },
        data: [],
        loadData: () => {
          let {pageConfig: {page, limit}, search} = this.sourceCodeConfig;
          shop.sourceCode({
            limit, page,
            userId: (this.user && this.user.userId) || null,
            shopId: this.id,
            keyword: search.keyword
          }).then(res => {
            let {retdata = [], count = 0} = res;
            this.sourceCodeConfig.data = retdata;
            this.sourceCodeConfig.pageConfig.total = count;
          })
        },
        pageConfig: {
          page: 1,
          limit: 12,
          total: 0,
          pageSizes: [1, 2, 5, 10].map(item => item * 12),
          handleSizeChange: (limit) => {
            this.sourceCodeConfig.pageConfig.page = 1;
            this.sourceCodeConfig.pageConfig.limit = limit;
            this.sourceCodeConfig.loadData();
          },
          handleCurrentChange: (page) => {
            this.sourceCodeConfig.pageConfig.page = page;
            this.sourceCodeConfig.loadData();
          }
        },
      },
      serviceConfig: {
        search: {
          keyword: null,
        },
        data: [],
        loadData: () => {
          let {pageConfig: {page, limit}, search} = this.serviceConfig;
          shop.service({
            limit, page,
            userId: (this.user && this.user.userId) || null,
            shopId: this.id,
            keyword: search.keyword,
          }).then(res => {
            let {retdata = [], count = 0} = res;
            this.serviceConfig.data = retdata;
            this.serviceConfig.pageConfig.total = count;
          })
        },
        pageConfig: {
          page: 1,
          limit: 12,
          total: 0,
          pageSizes: [1, 2, 5, 10].map(item => item * 12),
          handleSizeChange: (limit) => {
            this.serviceConfig.pageConfig.page = 1;
            this.serviceConfig.pageConfig.limit = limit;
            this.serviceConfig.loadData();
          },
          handleCurrentChange: (page) => {
            this.serviceConfig.pageConfig.page = page;
            this.serviceConfig.loadData();
          }
        },
      },
      designConfig: {
        search: {
          keyword: null,
        },
        data: [],
        loadData: () => {
          let {pageConfig: {page, limit}, search} = this.designConfig;
          shop.design({
            limit, page,
            userId: (this.user && this.user.userId) || null,
            shopId: this.id,
            keyword: search.keyword
          }).then(res => {
            let {retdata = [], count = 0} = res;
            this.designConfig.data = retdata;
            this.designConfig.pageConfig.total = count;
          })
        },
        pageConfig: {
          page: 1,
          limit: 12,
          total: 0,
          pageSizes: [1, 2, 5, 10].map(item => item * 12),
          handleSizeChange: (limit) => {
            this.designConfig.pageConfig.page = 1;
            this.designConfig.pageConfig.limit = limit;
            this.designConfig.loadData();
          },
          handleCurrentChange: (page) => {
            this.designConfig.pageConfig.page = page;
            this.designConfig.loadData();
          }
        },
      },
      docConfig: {
        search: {
          keyword: null,
        },
        data: [],
        loadData: () => {
          let {pageConfig: {page, limit}, search} = this.docConfig;
          shop.document({
            limit, page,
            userId: (this.user && this.user.userId) || null,
            shopId: this.id,
            keyword: search.keyword
          }).then(res => {
            let {retdata = [], count = 0} = res;
            this.docConfig.data = retdata;
            this.docConfig.pageConfig.total = count;
          })
        },
        pageConfig: {
          page: 1,
          limit: 12,
          total: 0,
          pageSizes: [1, 2, 5, 10].map(item => item * 12),
          handleSizeChange: (limit) => {
            this.docConfig.pageConfig.page = 1;
            this.docConfig.pageConfig.limit = limit;
            this.docConfig.loadData();
          },
          handleCurrentChange: (page) => {
            this.docConfig.pageConfig.page = page;
            this.docConfig.loadData();
          }
        },
      }
    }
  },
  methods: {
    getShopInfo() {
      return this.$request.post({
        reqcode: '1017',
        reqdesc: '店铺详情',
        shopId: this.id,
        userId: (this.user && this.user.userId) || null
      }).then(res => {
        const {retdata: shopInfo} = res;
        if (shopInfo) {
          this.shopInfo = shopInfo
        }
      }).catch(err => {
        if (err.retCode === '9000') {
          this.notExist = true;
        }
        return Promise.reject(err);
      });
    },
    /**
     * 收藏店铺
     */
    favoriteShop() {
      if (!this.shopInfo) {
        return;
      }
      this.collectionBtnCanClickFlag = false;
      collection.addFavorite({
        userId: (this.user && this.user.userId) || null,
        targetType: 1,
        targetId: this.shopInfo.shopid
      }).then(res => {
        const {retdata} = res;
        this.shopInfo.favorite = retdata;
        if (retdata) {
          this.$message.success('收藏成功')
        } else {
          this.$message.success('取消收藏成功')
        }
      }).finally(() => {
        this.collectionBtnCanClickFlag = true
      })
    },
    getProductReleaseTerms() {
      common.loadProtocol({
        protocolName: '平台服务条款'
      }).then(res => {
        const {retdata} = res;
        this.productReleaseTerms = retdata
      })
    },
    openPay(){
      if (!this.read){
        this.$message.warning('请确认阅读条款');
        return
      }
      this.openPayAfterFlag =false
      this.viewPhoneDialogConfig.showPayDialog = true;
    }
  },
  computed: {
    shopAddress() {
      let ret = '';
      if (this.shopInfo) {
        let province = area.parseProvince(this.shopInfo.provinceCode);
        let city = area.parseCity(this.shopInfo.cityCode);
        ret = `${province.name}-${city.name}`;
      }
      return ret;
    },
  },
  beforeMount() {
    this.getShopInfo();
    this.sourceCodeConfig.loadData();
    this.docConfig.loadData();
    this.serviceConfig.loadData();
    this.designConfig.loadData();
    this.getProductReleaseTerms()
  }
}
</script>

<style scoped lang="scss">
.shop-introduction .el-avatar {
  height: 50px;
  width: 50px;
}

.shop-introduction .shop-info div {
  line-height: 28px;
}

.shop-introduction .shop-info div .title {
  width: 45px;
  text-align: right;
}

.shop-introduction .shop-info div .value {
  flex: 1;
}

.shop-introduction .shop-info span {
  display: inline-block;
  width: 50px;
}

.custom-tabs ::v-deep .el-tabs__header {
  border: none;
  background-color: #fff;
}

.custom-tabs ::v-deep .el-tabs__header .el-tabs__nav {
  border: none;
}

.custom-tabs ::v-deep .el-tabs__header .el-tabs__item {
  border: none;
}

.custom-tabs ::v-deep > .el-tabs__header .el-tabs__item {
  height: 60px;
  line-height: 60px;
  font-size: 16px;
  padding: 0 40px !important;
}

.custom-tabs ::v-deep .el-tabs__item.is-active {
  color: #F90;
  font-weight: bold;
}

.custom-tabs ::v-deep .el-tabs__item:hover {
  color: #F90;
}
::v-deep .el-dialog__body {

  overflow-y: auto;
}
.goods-title-bar {
  position: relative;
}

.goods-title-bar:before {
  content: '';
  display: block;
  position: absolute;
  top: 15px;
  left: 0;
  bottom: 15px;
  border-left: 3px solid #f29340;
}

.goods-title-bar ::v-deep .el-input-group {
  width: 250px;
}

.goods-container ::v-deep .el-col {
  margin-bottom: 15px;
}

.goods-container .goods-card {
  padding: 8px;
  cursor: pointer;
}

.auth_ok_icon {
  height: 12px;
  width: 12px;
  object-fit: cover;
  vertical-align: middle;
  margin-right: 3px;
}
</style>
